import React, { ReactNode } from "react"

import { MainLayout, InternalSection } from "../../molecules"
import { DefaultTemplateProps } from "../HomeTemplate/HomeTemplate"

export interface InternalTemplateProps extends DefaultTemplateProps {
  title: string
  children: ReactNode
}

const InternalTemplate: React.FC<InternalTemplateProps> = (props) => {
  const {
    links,
    twitter,
    linkedin,
    instagram,
    facebook,
    phone,
    email,
    title,
    children,
  } = props

  return (
    <MainLayout
      links={links}
      phone={phone}
      email={email}
      twitter={twitter}
      linkedin={linkedin}
      instagram={instagram}
      facebook={facebook}
      isHome={false}
    >
      <InternalSection title={title}>{children}</InternalSection>
    </MainLayout>
  )
}

InternalTemplate.defaultProps = {}

export default InternalTemplate
