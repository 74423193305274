import { createGlobalStyle } from "styled-components"
import { normalize } from "polished"

const GlobalStyle = createGlobalStyle`
    ${normalize()}
    body, html {
        height: 100%;
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
    }
`

export default GlobalStyle
