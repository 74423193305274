import styled from "styled-components"
import tw from "twin.macro"

export interface WrapperProps {
  width?: number
  maxWidth?: number
}

const Wrapper = styled.div<WrapperProps>`
  ${tw`mx-auto`}
  ${({ maxWidth }) =>
    maxWidth &&
    `
    max-width: ${maxWidth}px;
  `}
  ${({ width }) =>
    width &&
    `
    width: ${width}%;
  `}
`

Wrapper.defaultProps = {
  width: 95,
  maxWidth: 1500,
}

export default Wrapper
