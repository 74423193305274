import { ReactChild } from "react"
import styled from "styled-components"
import tw from "twin.macro"

// @ts-ignore
import media from "../../../../config/media"

export interface HeroProps {
  img: string
  children?: ReactChild
  height?: number
}

const Hero = styled.div<HeroProps>`
  height: ${({ height }) => (height ? `${height - 200}px` : "300px")};
  background: #efefef;

  ${tw`bg-cover bg-center relative text-white text-xl flex items-center`}
  ${({ img }) => img && `background-image: url(${img});`}

  @media screen and (min-width: ${media.md}) {
    height: ${({ height }) => (height ? `${height}px` : "500px")};
  }
`

export default Hero
