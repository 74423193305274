import React, { ReactChild, ReactNode } from "react"
import { MainLayout, ContactSection, ProjectSection } from "../../molecules"
import { PhotoSection } from "../../organisms"
import { IPhoto } from "../../organisms/PhotoSection/PhotoSection"

import { DefaultTemplateProps } from "../HomeTemplate/HomeTemplate"

export interface ProjectTemplateProps extends DefaultTemplateProps {
  contactUsText: ReactNode | ReactChild
  projectDescription: ReactNode | ReactChild
  projectTitle: ReactNode | ReactChild
  projectImage: ReactNode | ReactChild
  photos?: IPhoto[]
}

const ProjectTemplate: React.FC<ProjectTemplateProps> = (
  props: ProjectTemplateProps
) => {
  const {
    links,
    projectTitle,
    projectImage,
    contactUsText,
    projectDescription,
    twitter,
    linkedin,
    instagram,
    facebook,
    phone,
    email,
    photos,
  } = props
  return (
    <MainLayout
      links={links}
      phone={phone}
      email={email}
      twitter={twitter}
      linkedin={linkedin}
      instagram={instagram}
      facebook={facebook}
      isHome={false}
    >
      <>
        <ProjectSection title={projectTitle} img={projectImage}>
          {projectDescription}
        </ProjectSection>
        <PhotoSection images={photos ?? []} />
        <ContactSection phone={phone} email={email}>
          {contactUsText}
        </ContactSection>
      </>
    </MainLayout>
  )
}

ProjectTemplate.defaultProps = {
  photos: [],
}

export default ProjectTemplate
