import React from "react"
import styled from "styled-components"
import tw from "twin.macro"
import Link from "gatsby-link"
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons"

import { Wrapper, Logo, Icon } from "../../atoms"
import Nav, { INavItem } from "../Nav/Nav"

// @ts-ignore
import media from "../../../../config/media"

const logoWidth = 200

const HeaderContainer = styled.header`
  ${tw`py-4 text-blue-600 bg-white`}
`

const HeaderGrid = styled.div`
  ${tw`md:flex justify-between content-center items-center`}
`

const LogoColumn = styled.div`
  ${tw`md:m-0 m-auto`}
  max-width: ${logoWidth}px;

  img {
    width: 100%;
    ${tw`transition duration-200`}
  }

  a:hover {
    img {
      transform: scale(1.1);
    }
  }
`

const RightColumn = styled.div`
  ${tw`w-full h-full md:grid justify-between`}

  grid-gap: 2rem;

  nav {
    ${tw`hidden md:flex`}
  }

  @media screen and (min-width: ${media.md}) {
    grid-template-columns: 1fr 250px;
    max-width: 800px;
  }
`

const Info = styled.div`
  ${tw`text-grey-500 italic md:text-left text-center md:m-0 mt-4`}

  a {
    ${tw`no-underline text-grey-500 hover:text-grey-800`}
  }
`

const InfoLine = styled.div`
  ${tw`text-lg md:mb-2`}

  div {
    ${tw`inline-block mr-2`}
  }
`

export interface HeaderProps {
  items: INavItem[]
  logoLink?: string
  phone: string
  email: string
  isHome?: boolean
}

const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
  const { items, logoLink, phone, email, isHome } = props
  return (
    <HeaderContainer>
      <Wrapper>
        <HeaderGrid>
          <LogoColumn>
            <Link title="Home Page" to={logoLink ?? "/"}>
              <Logo />
            </Link>
          </LogoColumn>
          <RightColumn>
            <Nav items={items} isHome={isHome} />
            <Info>
              <InfoLine>
                <a href={`tel:${phone}`} target="_blank" rel="noreferrer">
                  <Icon icon={faPhone} /> {phone}
                </a>
              </InfoLine>
              <InfoLine>
                <a href={`mailto:${email}`} target="_blank" rel="noreferrer">
                  <Icon icon={faEnvelope} /> {email}
                </a>
              </InfoLine>
            </Info>
          </RightColumn>
        </HeaderGrid>
      </Wrapper>
    </HeaderContainer>
  )
}

Header.defaultProps = {
  items: [],
}

export default Header
