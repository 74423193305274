import React, { ReactChild } from "react"
import styled from "styled-components"
import tw from "twin.macro"

import Header from "../Header/Header"
import Footer from "../Footer/Footer"
import { INavItem } from "../Nav/Nav"

const LayoutContainer = styled.div`
  ${tw`bg-grey-800 h-full`}
  min-height: 100vh;
`

const Body = styled.div`
  ${tw`bg-white`}
  min-height: 500px;
`

export interface MainLayoutProps {
  children?: ReactChild
  links: INavItem[]
  phone: string
  email: string
  facebook?: string
  linkedin?: string
  twitter?: string
  instagram?: string
  isHome?: boolean
}

const MainLayout: React.FC<MainLayoutProps> = (props: MainLayoutProps) => {
  const {
    links,
    phone,
    email,
    facebook,
    linkedin,
    twitter,
    instagram,
    children,
    isHome,
  } = props

  return (
    <LayoutContainer>
      <Header items={links} phone={phone} email={email} isHome={isHome} />
      <Body>{children}</Body>
      <Footer
        facebook={facebook}
        linkedin={linkedin}
        twitter={twitter}
        instagram={instagram}
      />
    </LayoutContainer>
  )
}

MainLayout.defaultProps = {
  links: [],
}

export default MainLayout
