import React from "react"
import Link from "gatsby-link"
import styled, { css } from "styled-components"
import tw from "twin.macro"
import slugify from "slugify"
import AnchorLink from "react-anchor-link-smooth-scroll"

const NavContainer = styled.nav`
  ${tw`flex justify-around items-center`}
`

const linkCss = css`
  ${tw`no-underline text-blue-600 p-2 px-4 uppercase font-bold transition hover:text-green-500`}
`

const DropdownContainer = styled.div`
  ${tw`absolute top-full left-1/2 bg-white p-6 box-border z-50 hidden`}
  width: 200px;
  transform: translateX(-50%);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);

  a {
    ${tw`text-sm block`}
  }
`

const NavLinkContainer = styled.div`
  ${tw`relative py-4`}

  &:hover {
    ${DropdownContainer} {
      ${tw`block`}
    }
  }
`

const NavLink = styled(Link)`
  ${linkCss}
`

const AnchorNavLink = styled(AnchorLink)`
  ${linkCss}
`

export interface IBaseNavItem {
  label: string
  url: string
  isAnchor?: boolean
  offset?: string
}

export interface INavItem extends IBaseNavItem {
  items?: IBaseNavItem[]
}

export interface NavProps {
  items: INavItem[]
  isHome?: boolean
}

interface NavLinkItemProps {
  label: string
  isHome: boolean
  url: string
  isAnchor?: boolean
  offset?: string
}

const NavLinkItem: React.FC<NavLinkItemProps> = (props) => {
  const { isAnchor, isHome, url, label, offset } = props

  return (
    <>
      {isAnchor ? (
        <>
          {isHome ? (
            <AnchorNavLink href={url} offset={offset}>
              {label}
            </AnchorNavLink>
          ) : (
            <NavLink to={`/${url}`}>{label}</NavLink>
          )}
        </>
      ) : (
        <NavLink to={url}>{label}</NavLink>
      )}
    </>
  )
}

const Nav: React.FC<NavProps> = (props: NavProps) => {
  const { items, isHome } = props

  return (
    <NavContainer>
      {items.map((item) => (
        <NavLinkContainer>
          <NavLinkItem
            key={slugify(item.label)}
            isHome={!!isHome}
            isAnchor={item.isAnchor}
            url={item.url}
            label={item.label}
            offset={item.offset}
          />
          {item.items && (
            <DropdownContainer>
              {item.items.map((sub) => (
                <NavLinkItem
                  key={slugify(sub.label)}
                  isHome={!!isHome}
                  isAnchor={sub.isAnchor}
                  url={sub.url}
                  label={sub.label}
                  offset={sub.offset}
                />
              ))}
            </DropdownContainer>
          )}
        </NavLinkContainer>
      ))}
    </NavContainer>
  )
}

Nav.defaultProps = {
  isHome: false,
}

export default Nav
