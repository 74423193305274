import React, { ReactChild, ReactNode } from "react"
import {
  MainLayout,
  ProjectGallerySection,
  ContactSection,
  HomeSection,
  WhoWeAreSection,
  BuilderSection,
  PhilosophySection,
} from "../../molecules"
import { HomeSlider } from "../../organisms"

// @types
import { ISlide } from "../../organisms/HomeSlider/HomeSlider"
import { INavItem } from "../../molecules/Nav/Nav"
import { ITradeIconStack } from "../../molecules/TradeIconStack/TradeIconStack"
import { IProjectBox } from "../../molecules/ProjectBox/ProjectBox"
import { ICompareRow } from "../../molecules/PhilosophySection/PhilosophySection"

export interface DefaultTemplateProps {
  links: INavItem[]
  phone: string
  email: string
  facebook?: string
  twitter?: string
  linkedin?: string
  instagram?: string
}

export interface HomeTemplateProps extends DefaultTemplateProps {
  title: string
  trades?: ITradeIconStack[]
  categories?: ITradeIconStack[]
  projects?: IProjectBox[]
  slides?: ISlide[]
  homeText: ReactChild | ReactNode
  projectText: ReactChild | ReactNode
  contactUsText: ReactChild | ReactNode
  whoText: ReactChild | ReactNode
  builderText: ReactChild | ReactNode
  whoImage?: string
  builderImage?: string
  philosophyTitle: string
  philosophyText: ReactChild | ReactNode
  compareBlocks: ICompareRow[]
}

const HomeTemplate: React.FC<HomeTemplateProps> = (
  props: HomeTemplateProps
) => {
  const {
    title,
    links,
    slides,
    phone,
    email,
    trades,
    homeText,
    projects,
    projectText,
    contactUsText,
    twitter,
    linkedin,
    instagram,
    facebook,
    whoText,
    whoImage,
    builderText,
    builderImage,
    philosophyTitle,
    philosophyText,
    compareBlocks,
    categories,
  } = props
  return (
    <MainLayout
      links={links}
      phone={phone}
      email={email}
      twitter={twitter}
      linkedin={linkedin}
      instagram={instagram}
      facebook={facebook}
      isHome
    >
      <>
        <HomeSlider slides={slides ?? []} delay={12} />
        <HomeSection title={title} iconStack={trades ?? []}>
          {homeText}
        </HomeSection>
        <WhoWeAreSection img={whoImage} categories={categories}>
          {whoText}
        </WhoWeAreSection>
        <BuilderSection img={builderImage}>{builderText}</BuilderSection>
        <ProjectGallerySection projectBoxes={projects ?? []}>
          {projectText}
        </ProjectGallerySection>
        <PhilosophySection
          title={philosophyTitle}
          mainText={philosophyText}
          compareBlocks={compareBlocks}
        />
        <ContactSection phone={phone} email={email}>
          {contactUsText}
        </ContactSection>
      </>
    </MainLayout>
  )
}

HomeTemplate.defaultProps = {
  links: [],
  trades: [],
  projects: [],
  slides: [],
}

export default HomeTemplate
