import React from "react"
import { graphql } from "gatsby"
import { faHome } from "@fortawesome/free-solid-svg-icons"

import { PageStructure } from "../ui/atoms"
import { HomeTemplate } from "../ui/templates"
import { ITradeIconStack } from "../ui/molecules/TradeIconStack/TradeIconStack"
import { IProjectBox } from "../ui/molecules/ProjectBox/ProjectBox"
import { INavItem, IBaseNavItem } from "../ui/molecules/Nav/Nav"

import "../styles/app.css"
import "../styles/global.css"
import { ICompareRow } from "../ui/molecules/PhilosophySection/PhilosophySection"

export const convertSlashToDash = (s: string): string => {
  let newstring = s.split("/").join("-")

  // First Character
  if (newstring.charAt(0) === "-") {
    newstring = newstring.substring(1)
  }

  // Last Character
  if (newstring.charAt(newstring.length - 1) === "-") {
    newstring = newstring.slice(0, -1)
  }

  return newstring
}

export const getNavLinks = (data: any): INavItem[] => {
  const navLinks: INavItem[] = []

  // Add Normal Links
  data.headerMenu.menuItems.nodes.forEach((node: any) => {
    navLinks.push({
      label: node.label,
      url: node.path,
    })
  })

  // Trade Menu
  const tradeItem: INavItem = {
    label: "Trades",
    url: "#",
    items: [],
  }

  data.tradeMenu.menuItems.nodes.forEach((node: any) => {
    ;(tradeItem.items as IBaseNavItem[]).push({
      label: node.label,
      url: `#${convertSlashToDash(node.path)}`,
      isAnchor: true,
      offset: "30",
    })
  })

  navLinks.push(tradeItem)

  navLinks.push({
    label: "CONTACT",
    url: "#contact",
    isAnchor: true,
    offset: "50",
  })

  return navLinks
}

const getTrades = (data: any): ITradeIconStack[] => [
  {
    icon: faHome,
    title: "Home Building",
    id: "trade-home-building",
    content: data.data.allWpPage.edges[0].node.homeFields.homeBuildingText,
  },
  {
    icon: faHome,
    title: "Remodeling",
    id: "trade-remodeling",
    content: data.data.allWpPage.edges[0].node.homeFields.remodelingText,
  },
]

const getCategories = (data: any): ITradeIconStack[] =>
  data.data.allWpTrade.edges.map((edge: any) => ({
    icon: faHome,
    title: edge.node.title,
    id: convertSlashToDash(edge.node.uri),
    content: String(edge.node.content),
  }))

const getProjects = (data: any): IProjectBox[] =>
  data.data.allWpProject.edges.map((project: any) => ({
    img: project.node.featuredImage.node?.localFile?.childImageSharp?.fluid
      ?.src,
    title: project.node.title,
    link: project.node.uri,
    buttonText: project.node.projectFields.buttonText,
  }))

const getSlides = (data: any) =>
  data.data.allWpSlide.edges.map((slide: any) => ({
    img: slide.node.featuredImage.node?.localFile?.childImageSharp?.fluid?.src,
    title: slide.node.title,
  }))

const getCompareBlocks = (data: any): ICompareRow[] =>
  data.data.allWpCompareBuilder.edges.map((node: any) => ({
    id: node.node.id,
    barnett: node.node.compareBuilderFields.barnett,
    other: node.node.compareBuilderFields.others,
  }))

// markup
const IndexPage = (data: any) => {
  const config = data.data.allWpConfig.edges[0].node.configFields
  const home = data.data.allWpPage.edges[0].node
  const { homeFields } = home
  const whoImage =
    homeFields.whoWeAreImage.localFile &&
    homeFields.whoWeAreImage.localFile.childImageSharp.fluid.src
  const builderImage =
    homeFields.meetBuilderImage.localFile &&
    homeFields.meetBuilderImage.localFile.childImageSharp.fluid.src

  return (
    <PageStructure title="Custom Carpentry & Fine Homes">
      <HomeTemplate
        title={home.title}
        phone={config.phone}
        email={config.email}
        philosophyText={homeFields.philosophyText}
        philosophyTitle={homeFields.philosophyTitle}
        projectText={config.projectGalleryText}
        contactUsText={config.contactUsText}
        homeText={home.content}
        links={getNavLinks(data.data)}
        trades={getTrades(data)}
        categories={getCategories(data)}
        projects={getProjects(data)}
        slides={getSlides(data)}
        whoText={homeFields.whoWeAreText}
        whoImage={whoImage}
        builderText={homeFields.meetBuilderText}
        builderImage={builderImage}
        compareBlocks={getCompareBlocks(data)}
      />
    </PageStructure>
  )
}

export default IndexPage

export const HomeQuery = graphql`
  query HomeQuery {
    allWpTrade {
      edges {
        node {
          uri
          title
          content
        }
      }
    }
    headerMenu: wpMenu(id: { eq: "dGVybToyMTk0" }) {
      id
      slug
      menuItems {
        nodes {
          id
          label
          path
        }
      }
    }
    tradeMenu: wpMenu(id: { eq: "dGVybToyMjAw" }) {
      id
      slug
      menuItems {
        nodes {
          id
          label
          path
        }
      }
    }
    allWpCompareBuilder(
      sort: { fields: contentType___node___menuPosition, order: ASC }
    ) {
      edges {
        node {
          id
          compareBuilderFields {
            others
            barnett
          }
        }
      }
    }
    allWpPage(filter: { isFrontPage: { eq: true } }) {
      edges {
        node {
          title
          content
          homeFields {
            homeBuildingText
            remodelingText
            whoWeAreText
            whoWeAreImage {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    src
                  }
                }
              }
            }
            meetBuilderText
            meetBuilderImage {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    src
                  }
                }
              }
            }
            philosophyText
            philosophyTitle
          }
        }
      }
    }
    allWpProject {
      edges {
        node {
          id
          title
          content
          uri
          projectFields {
            buttonText
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 600) {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
    allWpSlide {
      edges {
        node {
          title
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1500) {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
    allWpConfig {
      edges {
        node {
          configFields {
            phone
            email
            contactUsText
            projectGalleryText
          }
        }
      }
    }
  }
`
