import React, { ReactChild, ReactNode } from "react"
import styled from "styled-components"
import tw from "twin.macro"

import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons"
import { Section, BlueHeading, Icon, Logo } from "../../atoms"

// @ts-ignore
import media from "../../../../config/media"
// @ts-ignore
import colors from "../../../../config/colors"

export interface ContactSectionProps {
  children: ReactChild | ReactNode
  phone?: string
  email?: string
}

const ContactGrid = styled.div`
  ${tw`grid grid-cols-1 md:grid-cols-2 px-4`}
  grid-column-gap: 2rem;
  align-items: center;
`

const InfoItem = styled.div`
  ${tw`mb-6`}

  a {
    ${tw`text-blue-600 no-underline text-base md:text-3xl flex hover:underline`}
  }
`

const IconBubble = styled.div`
  ${tw`rounded-full bg-blue-600 text-white flex items-center content-center justify-center text-base md:text-xl mr-2 md:mr-6`}
  height: 25px;
  width: 25px;

  @media screen and (min-width: ${media.md}) {
    height: 35px;
    width: 35px;
  }
`

const ContactContent = styled.div`
  ${tw`mb-8`}
`

const ImageColumn = styled.div`
  ${tw`text-center md:block hidden`}

  img {
    ${tw`m-auto`}
  }
`

const ContactSection: React.FC<ContactSectionProps> = (
  props: ContactSectionProps
) => {
  const { children, phone, email } = props
  return (
    <Section bgColor={colors.grey200}>
      <ContactGrid>
        <div id="contact">
          <ContactContent>
            <BlueHeading>CONTACT US TODAY!</BlueHeading>
            {children}
          </ContactContent>
          <InfoItem>
            <a href={`tel:${phone}`} target="_blank" rel="noreferrer">
              <IconBubble>
                <Icon icon={faPhone} />
              </IconBubble>
              {phone}
            </a>
          </InfoItem>
          <InfoItem>
            <a href={`mailto:${email}`} target="_blank" rel="noreferrer">
              <IconBubble>
                <Icon icon={faEnvelope} />
              </IconBubble>
              {email}
            </a>
          </InfoItem>
        </div>
        <ImageColumn>
          <Logo />
        </ImageColumn>
      </ContactGrid>
    </Section>
  )
}

ContactSection.defaultProps = {}

export default ContactSection
