module.exports = {
  white: "#fff",
  black: "#000",

  // Grey Colors
  grey200: "#eaeaea",
  grey400: "#737373",
  grey500: "#575757",
  grey600: "#404040",
  grey800: "#141414",

  // Blue Colors
  blue600: "#092d74",

  // Green Colors
  green500: "#60a511",
}
