import React from "react"
import styled from "styled-components"
import tw from "twin.macro"
import {
  faTimesCircle,
  faCheckCircle,
} from "@fortawesome/free-regular-svg-icons"

import { Icon } from "../../atoms"

export interface CompareRowProps {
  other: string
  barnett: string
}

const convertHTML = (str: string) => str.replace(/(?:\r\n|\r|\n)/g, "<br>")

const ContentArea = styled.div`
  ${tw`md:grid`}
  grid-template-columns: auto 1fr;
  grid-gap: 1rem;

  svg {
    ${tw`md:block hidden md:text-5xl text-xl`}
  }
`

const NegativeContent = styled(ContentArea)`
  svg {
    ${tw`text-red-500`}
  }
`

const PositiveContent = styled(ContentArea)`
  svg {
    ${tw`text-green-500`}
  }
`

const CompareRow: React.FC<CompareRowProps> = (props) => {
  const { other, barnett } = props

  return (
    <tr>
      <td>
        <NegativeContent>
          <Icon icon={faTimesCircle} />
          <div dangerouslySetInnerHTML={{ __html: convertHTML(other) }} />
        </NegativeContent>
      </td>
      <td>
        <PositiveContent>
          <Icon icon={faCheckCircle} />
          <div dangerouslySetInnerHTML={{ __html: convertHTML(barnett) }} />
        </PositiveContent>
      </td>
    </tr>
  )
}

export default CompareRow
