import Link, { GatsbyLinkProps } from "gatsby-link"
import styled from "styled-components"
import tw from "twin.macro"

export type ButtonProps = Omit<GatsbyLinkProps<Record<string, unknown>>, "ref">

const Button = styled(Link)`
  ${tw`bg-white text-blue-600 border-blue-600 border-2 border-solid no-underline p-2 md:p-4 text-sm md:text-base inline-block font-bold uppercase hover:bg-blue-600 hover:text-white transition duration-300 hover:cursor-pointer`}

  &:hover {
    transform: scale(1.1);
  }
`

export default Button
