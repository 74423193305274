import React from "react"
import styled from "styled-components"
import tw from "twin.macro"
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons"

import { Wrapper, Icon } from "../../atoms"

const FooterContainer = styled.footer`
  ${tw`bg-grey-800 text-white pt-6 pb-20 text-sm`}

  a {
    ${tw`text-white no-underline`}

    &:hover {
      ${tw`underline`}
    }
  }
`

const FooterGrid = styled.div`
  ${tw`grid md:grid-cols-2 grid-cols-1 text-center md:text-left`}
  grid-gap: 2rem;
`

const SocialColumn = styled.div`
  ${tw`text-xl flex justify-end`}

  div {
    ${tw`ml-16`}
  }
`

export interface FooterProps {
  facebook?: string
  linkedin?: string
  twitter?: string
  instagram?: string
}

const Footer: React.FC<FooterProps> = (props: FooterProps) => {
  const { facebook, linkedin, twitter, instagram } = props
  const year = new Date().getFullYear()

  return (
    <FooterContainer>
      <Wrapper>
        <FooterGrid>
          <div>
            Developed By{" "}
            <a href="https://toddprod.com" target="_blank" rel="noreferrer">
              Todd Productions Inc.
            </a>{" "}
            {year}
          </div>
          <SocialColumn>
            {facebook && (
              <a href={facebook} target="_blank" rel="noreferrer">
                <Icon icon={faFacebook} />
              </a>
            )}
            {instagram && (
              <a href={instagram} target="_blank" rel="noreferrer">
                <Icon icon={faInstagram} />
              </a>
            )}
            {twitter && (
              <a href={twitter} target="_blank" rel="noreferrer">
                <Icon icon={faTwitter} />
              </a>
            )}
            {linkedin && (
              <a href={linkedin} target="_blank" rel="noreferrer">
                <Icon icon={faLinkedin} />
              </a>
            )}
          </SocialColumn>
        </FooterGrid>
      </Wrapper>
    </FooterContainer>
  )
}

Footer.defaultProps = {}

export default Footer
