import React, { useState, useEffect } from "react"
import styled from "styled-components"
import tw from "twin.macro"

import { Slide } from "../../molecules"

// @ts-ignore
import media from "../../../../config/media"

export interface ISlide {
  img: string
  title: string
}

export interface HomeSliderProps {
  slides: ISlide[]
  delay?: number
}

const SlideContainer = styled.section`
  ${tw`relative w-full overflow-hidden`}
  height: 300px;

  @media screen and (min-width: ${media.md}) {
    height: 500px;
  }
`

const BubbleContainer = styled.div`
  ${tw`absolute bottom-0 w-full flex justify-center items-center z-10`}
  height: 50px;
`

const Bubble = styled.div<{ active?: boolean }>`
  ${tw`transition-all mx-2 rounded-full border-2 border-solid cursor-pointer`}
  height: 14px;
  width: 14px;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.5);

  &:hover {
    transform: scale(1.5);
  }

  ${({ active }) =>
    active ? tw`border-green-500 bg-green-500` : tw`border-white bg-blue-600`}
`

const Slider = styled.div<{ position: number; width: number }>`
  ${tw`transition-all`}
  white-space: nowrap
  height: 300px;
  ${({ width }) => width && `width: ${width}vw`};

  @media screen and (min-width: ${media.md}) {
    height: 500px;
  }

  ${({ position }) =>
    position &&
    `
transform: translateX(-${position}vw);
`}
`

const HomeSlider: React.FC<HomeSliderProps> = (props: HomeSliderProps) => {
  const { slides, delay } = props
  const [index, setIndex] = useState<number>(0)
  const [auto, setAuto] = useState<any>(null)

  // Start Auto Play
  const startAuto = () => {
    const interval = setInterval(
      () => {
        const length = slides ? slides.length : 0
        setIndex((current) => (current === length - 1 ? 0 : current + 1))
      },
      delay ? delay * 1000 : 5000
    )

    setAuto(interval)
  }

  // Clear Auto
  const clearAuto = () => {
    clearInterval(auto)
    setAuto(null)
  }

  // Switching Slide Logic
  const bubbleClick = (ix: number) => {
    clearAuto()
    setIndex(ix)
    startAuto()
  }

  // Automation
  useEffect(() => {
    clearAuto()
    startAuto()

    return () => clearAuto()
  }, [])

  return (
    <SlideContainer>
      <Slider position={index * 100} width={slides.length * 100}>
        {slides.map((slide) => (
          <Slide img={slide.img} title={slide.title} />
        ))}
      </Slider>
      <BubbleContainer>
        {slides.map((_, ix) => (
          <Bubble active={index === ix} onClick={() => bubbleClick(ix)} />
        ))}
      </BubbleContainer>
    </SlideContainer>
  )
}

HomeSlider.defaultProps = {
  slides: [],
  delay: 5,
}

export default HomeSlider
