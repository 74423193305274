import styled from "styled-components"

export interface IconContainerProps {
  borderColor?: string
  bgColor?: string
  iconColor?: string
  size?: string
  padding?: string
}

const IconContainer = styled.div<IconContainerProps>`
  border: 3px solid;
  border-color: ${({ borderColor }) => borderColor || "#000000"};
  border-radius: 50%;
  padding: ${({ padding }) => padding || "25px"}; 25px;
  background-color: ${({ bgColor }) => bgColor || "#fff"};
  color: ${({ iconColor }) => iconColor || "#0b3270"};
  font-size: ${({ size }) => size || "3rem"};
`

export default IconContainer
