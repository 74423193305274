import React, { ReactChild, ReactNode } from "react"
import styled from "styled-components"
import tw from "twin.macro"

import { Section, BlueHeading, BreakLine } from "../../atoms"
import { ProjectBox } from ".."

import { IProjectBox } from "../ProjectBox/ProjectBox"

// @ts-ignore
import colors from "../../../../config/colors"

export interface ProjectGallerySectionProps {
  projectBoxes: IProjectBox[]
  children: ReactChild | ReactNode
}

const ProjectGrid = styled.div`
  ${tw`grid md:grid-cols-3 grid-cols-1`}
  grid-gap: 3rem;
`

const ProjectContent = styled.div`
  ${tw`text-center m-auto mb-8 md:mb-16`}
  max-width: 800px;
`

const ProjectGallerySection: React.FC<ProjectGallerySectionProps> = (
  props: ProjectGallerySectionProps
) => {
  const { children, projectBoxes } = props
  return (
    <Section bgColor={colors.grey200}>
      <ProjectContent id="projects">
        <BlueHeading>PROJECT GALLERY</BlueHeading>
        <BreakLine />
        <div dangerouslySetInnerHTML={{ __html: String(children) }} />
      </ProjectContent>
      <ProjectGrid>
        {projectBoxes.map((box) => (
          <ProjectBox
            img={box.img}
            title={box.title}
            size={box.size}
            link={box.link}
          />
        ))}
      </ProjectGrid>
    </Section>
  )
}

export default ProjectGallerySection
