import React, { ReactNode } from "react"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import styled from "styled-components"
import tw from "twin.macro"

// @ts-ignore
import colors from "../../../../config/colors"

import {
  BlueHeading,
  BreakLine,
  CenterColumn,
  Icon,
  IconContainer,
} from "../../atoms"

export interface ITradeIconStack {
  icon: IconProp
  id: string
  title: string
  content: string | ReactNode
  index?: number
}

export interface TradeIconStackProps {
  icon: IconProp
  title: string
  content: string
  index: number
  anchorID: string
}

const Col = styled.div`
  ${tw`inline-block mb-4`}
`

const TradeHeading = styled(BlueHeading)`
  ${tw`text-3xl`}
`

const TradeIconStack: React.FC<TradeIconStackProps> = (
  props: TradeIconStackProps
) => {
  const { icon, title, content, index, anchorID } = props
  const isEven = index % 2
  const bgColor = isEven ? colors.green500 : colors.white
  const iconColor = isEven ? colors.white : colors.blue600
  const borderColor = isEven ? colors.green500 : colors.blue600
  const mainColor = isEven ? colors.green500 : colors.blue600

  return (
    <CenterColumn>
      <a id={`${anchorID}`}> </a>
      <Col>
        <IconContainer
          bgColor={bgColor}
          iconColor={iconColor}
          borderColor={borderColor}
        >
          <Icon icon={icon} />
        </IconContainer>
      </Col>
      <TradeHeading textColor={mainColor}>{title}</TradeHeading>
      <BreakLine color={mainColor} />
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </CenterColumn>
  )
}

TradeIconStack.defaultProps = {}

export default TradeIconStack
