import React, { ReactNode } from "react"
import styled from "styled-components"
import tw from "twin.macro"

import Wrapper from "../Wrapper/Wrapper"

interface SectionStyleProps {
  children?: ReactNode
  bgColor?: string
  textColor?: string
  minHeight?: number
}

export type SectionProps = SectionStyleProps

const SectionStyle = styled.section<SectionStyleProps>`
  ${tw`py-16 leading-8`}
  ${({ bgColor }) => (bgColor ? `background-color: ${bgColor};` : tw`bg-white`)}
  ${({ textColor }) => (textColor ? `color: ${textColor};` : tw`text-blue-600`)}
  ${({ minHeight }) =>
    minHeight &&
    `
    min-height: ${minHeight}px;
    ${Wrapper} {
      min-height: ${minHeight}px;
    }
  `}
`

const Section: React.FC<SectionProps> = (props: SectionProps) => {
  const { children, bgColor, textColor, minHeight } = props

  return (
    <SectionStyle minHeight={minHeight} bgColor={bgColor} textColor={textColor}>
      <Wrapper>{children}</Wrapper>
    </SectionStyle>
  )
}

export default Section

Section.defaultProps = {}
