import React, { ReactChild, ReactNode } from "react"
import styled from "styled-components"
import tw from "twin.macro"

import { Section, BlueHeading, ImgCaption } from "../../atoms"

// @ts-ignore
import media from "../../../../config/media"

export interface BuilderSectionProps {
  children: ReactChild | ReactNode
  img?: string
}

const WhoGrid = styled.div`
  ${tw`grid grid-cols-1 md:grid-cols-2 mx-4`}
  grid-column-gap: 2rem;
  align-items: center;
`

const ContactContent = styled.div`
  ${tw`mb-8`}
`

const ImageColumn = styled.div`
  ${tw`text-center md:block`}

  img {
    height: 300px;
    width: 300px;
    max-width: 100%;
    ${tw`m-auto object-cover md:mt-0 mt-4`}
  }

  @media screen and (min-width: ${media.md}) {
    img {
      height: 500px;
      width: 500px;
    }
  }
`

const BuilderSection: React.FC<BuilderSectionProps> = (
  props: BuilderSectionProps
) => {
  const { children, img } = props
  return (
    <Section>
      <WhoGrid>
        <div id="meetbuilder">
          <ContactContent>
            <BlueHeading>MEET YOUR BUILDER</BlueHeading>
            <div dangerouslySetInnerHTML={{ __html: String(children) }} />
          </ContactContent>
        </div>
        <ImageColumn>
          {img && <img src={img} alt="Meet Your Builder" />}
          <ImgCaption>Dayton with his own equipment</ImgCaption>
        </ImageColumn>
      </WhoGrid>
    </Section>
  )
}

BuilderSection.defaultProps = {}

export default BuilderSection
