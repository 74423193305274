import React, { ReactNode } from "react"
import styled from "styled-components"
import tw from "twin.macro"

import { Section, BlueHeading, BreakLine, Wrapper } from "../../atoms"
import TradeIconStack, {
  ITradeIconStack,
} from "../TradeIconStack/TradeIconStack"

export interface HomeSectionProps {
  title: string
  iconStack: ITradeIconStack[]
  children: ReactNode
}

const IconGrid = styled.div`
  ${tw`grid grid-cols-1 md:grid-cols-2`}
  grid-gap: 3rem;
`

const HomeContent = styled.div`
  ${tw`text-center m-auto mb-16`}
  max-width: 900px;
`

const HomeSection: React.FC<HomeSectionProps> = (props: HomeSectionProps) => {
  const { title, children, iconStack } = props
  return (
    <Section>
      <HomeContent>
        <BlueHeading>{title}</BlueHeading>
        <BreakLine />
        {children && (
          <div dangerouslySetInnerHTML={{ __html: String(children) }} />
        )}
      </HomeContent>
      <Wrapper maxWidth={900}>
        <IconGrid>
          {iconStack.map((icon, ix) => (
            <TradeIconStack
              icon={icon.icon}
              title={icon.title}
              content={icon.content}
              index={ix}
            />
          ))}
        </IconGrid>
      </Wrapper>
    </Section>
  )
}

HomeSection.defaultProps = {}

export default HomeSection
