import React, { ReactChild, ReactNode } from "react"
import styled from "styled-components"
import tw from "twin.macro"

import { Section, BlueHeading } from "../../atoms"

export interface InternalSectionProps {
  children: ReactChild | ReactNode
  title?: string
}

const Content = styled.div`
  ${tw`mt-4`}

  &, p {
    ${tw`m-0 p-0 text-grey-500`}
    line-height: 175%;
  }

  em,
  i {
    ${tw`italic`}
  }

  strong {
    ${tw`font-bold`}
  }

  p {
    ${tw`mb-4`}
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    ${tw`m-0 p-0 mb-6 font-bold`}
  }

  img {
    max-width: 100%;
  }
`

const InternalSection: React.FC<InternalSectionProps> = (
  props: InternalSectionProps
) => {
  const { title, children } = props
  return (
    <Section>
      <BlueHeading>{title}</BlueHeading>
      <Content>{children}</Content>
    </Section>
  )
}

export default InternalSection
