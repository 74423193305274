import React from "react"
import styled from "styled-components"
import tw from "twin.macro"

import ModalBox, { ModalBoxProps } from "../ModalBox/ModalBox"

const Backdrop = styled.div<{ on?: boolean }>`
  ${tw`h-full w-full fixed top-0 bottom-0 left-0 right-0 z-50`}
  background: rgba(0, 0, 0, 0.4);

  display: ${({ on }) => (on ? "block" : "none")};
`

const ContentOverflow = styled.div`
  ${tw`h-full w-full overflow-auto box-border p-10 flex justify-center items-start content-center`}
`

export interface ModalProps extends ModalBoxProps {
  on?: boolean
  toggle?: () => void
}

const Modal: React.FC<ModalProps> = (props: ModalProps) => {
  const { children, toggle, on, maxWidth, minWidth } = props

  return (
    <Backdrop on={on}>
      <ContentOverflow>
        <ModalBox onClose={toggle} maxWidth={maxWidth} minWidth={minWidth}>
          {children}
        </ModalBox>
      </ContentOverflow>
    </Backdrop>
  )
}

Modal.defaultProps = {
  on: false,
}

export default Modal
