import React, { ReactChild, ReactNode } from "react"
import styled from "styled-components"
import tw from "twin.macro"

import { Section, BlueHeading, BreakLine, Logo } from "../../atoms"
import CompareRow from "../CompareRow/CompareRow"

export interface PhilosophySectionProps {
  title: string
  mainText: ReactChild | ReactNode
  compareBlocks: ICompareRow[]
}

export interface ICompareRow {
  id: string
  barnett: string
  other: string
}

const PhiloTopSection = styled.div`
  ${tw`text-center m-auto mb-16`}
  max-width: 800px;
`

const CuttingSection = styled.div`
  ${tw`m-auto bg-white p-10`}
  max-width: 1200px;

  ul,
  ol {
    ${tw`py-6 pl-16`}

    li {
      ${tw`mb-2`}
    }
  }

  ul li {
    ${tw`list-disc`}
  }
`

const CuttingGrid = styled.div`
  ${tw`grid items-center content-center`}
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
`

const OtherTitle = styled.div`
  ${tw`text-center italic text-grey-500 text-xl`}
`

const CompareTable = styled.table`
  ${tw`w-full mt-4`}

  tr {
    td {
      ${tw`w-1/2 box-border p-5 border border-grey-400`}
    }
  }
`

const LogoTitle = styled.div`
  ${tw`text-center`}

  img, svg {
    width: 150px;
    ${tw`m-auto`}
  }
`

const Content = styled.div``

const PhilosophySection: React.FC<PhilosophySectionProps> = (
  props: PhilosophySectionProps
) => {
  const { title, mainText, compareBlocks } = props
  return (
    <Section>
      <PhiloTopSection id="philosophy">
        <BlueHeading>{title}</BlueHeading>
        <BreakLine />
        {mainText && (
          <Content dangerouslySetInnerHTML={{ __html: String(mainText) }} />
        )}
      </PhiloTopSection>
      <CuttingSection>
        <CuttingGrid>
          <OtherTitle>Other Builders:</OtherTitle>
          <LogoTitle>
            <Logo />
          </LogoTitle>
        </CuttingGrid>
        <CompareTable>
          {compareBlocks.map((block) => (
            <CompareRow
              key={block.id}
              barnett={block.barnett}
              other={block.other}
            />
          ))}
        </CompareTable>
      </CuttingSection>
    </Section>
  )
}

PhilosophySection.defaultProps = {}

export default PhilosophySection
