import React, { ReactChild, ReactNode } from "react"
import styled from "styled-components"
import tw from "twin.macro"

const BoxContainer = styled.div<{ maxWidth?: number; minWidth?: number }>`
  ${tw`bg-white relative shadow-md border-grey-200 border-solid border rounded`}

  ${({ minWidth }) =>
    minWidth &&
    `
    min-width: ${minWidth}px;
  `}

  ${({ maxWidth }) =>
    maxWidth &&
    `
    max-width: ${maxWidth}px;
  `}
`

const BoxContent = styled.div`
  ${tw`p-10 w-full pt-14 box-border`}

  img {
    max-width: 100%;
    max-height: 700px;
  }
`

const BoxClose = styled.div`
  ${tw`text-grey-500 bg-grey-200 rounded-full flex justify-center content-center items-center absolute right-0 text-xl cursor-pointer mr-4 mt-2 transition hover:bg-grey-500 hover:text-white`}
  transform: scale(0.9);
  height: 30px;
  width: 30px;

  &:hover {
    transform: scale(1);
  }
`

export interface ModalBoxProps {
  children: ReactNode | ReactChild
  maxWidth?: number
  minWidth?: number
  onClose?: () => void
}

const ModalBox: React.FC<ModalBoxProps> = (props: ModalBoxProps) => {
  const { children, maxWidth, minWidth, onClose } = props

  return (
    <BoxContainer maxWidth={maxWidth} minWidth={minWidth}>
      <BoxClose onClick={() => onClose && onClose()}>x</BoxClose>
      <BoxContent>{children}</BoxContent>
    </BoxContainer>
  )
}

ModalBox.defaultProps = {
  maxWidth: 800,
  minWidth: 500,
}

export default ModalBox
