import React from "react"
import styled from "styled-components"
import AnchorLink from "react-anchor-link-smooth-scroll"
import tw from "twin.macro"

import { Hero, Wrapper } from "../../atoms"

const Button = styled(AnchorLink)`
  ${tw`bg-white text-blue-600 border-blue-600 border-2 border-solid no-underline p-2 md:p-4 text-sm md:text-base inline-block font-bold uppercase hover:bg-blue-600 hover:text-white transition duration-300 hover:cursor-pointer`}

  &:hover {
    transform: scale(1.1);
  }
`

const Heading = styled.h1`
  ${tw`text-white font-bold uppercase text-2xl md:text-5xl m-0 mb-4`}
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.8);
  line-height: 150%;
`

const HeroWrapper = styled(Wrapper)`
  ${tw`text-center`}
`

const SlideContainer = styled.div`
  ${tw`inline-block`}
  width: 100vw;
`

export interface SlideProps {
  title: string
  img: string
  buttonText: string
  height?: number
}

const Slide = (props: SlideProps) => {
  const { img, title, buttonText, height } = props

  return (
    <SlideContainer>
      <Hero img={img} height={height}>
        <HeroWrapper maxWidth={800}>
          <Heading>{title}</Heading>
          <Button href="#projects" offset="50">
            {buttonText}
          </Button>
        </HeroWrapper>
      </Hero>
    </SlideContainer>
  )
}

Slide.defaultProps = {
  buttonText: "Project Gallery",
}

export default Slide
