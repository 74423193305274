import styled from "styled-components"
import tw from "twin.macro"

// @ts-ignore
import colors from "../../../../config/colors"

export interface BlueHeadingProps {
  textColor?: string
}

const BlueHeading = styled.h2<BlueHeadingProps>`
  margin: 0;
  ${tw`uppercase text-2xl font-bold md:text-4xl mt-2 mb-6`}
  color: ${({ textColor }) => textColor ?? colors.blue600};
`

export default BlueHeading
