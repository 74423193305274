import React, { useState } from "react"
import styled from "styled-components"
import tw from "twin.macro"

import { Section, Modal } from "../../atoms"

// @ts-ignore
import colors from "../../../../config/colors"
// @ts-ignore
import media from "../../../../config/media"

export interface IPhoto {
  src: string
  alt?: string
}

export interface PhotoSectionProps {
  images: IPhoto[]
}

const PhotoFlex = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 2rem;
`

const ImageContainer = styled.div<{ src?: string }>`
  ${tw`cursor-pointer bg-cover bg-center`}
  background-color: #efefef;
  height: 250px;
  width: 250px;

  ${({ src }) =>
    src &&
    `
    background-image: url(${src});
  `}

  @media screen and (min-width: ${media.md}) {
    height: 380px;
    width: 380px;
  }
`

const ModalImage = styled.img``

const ModalContent = styled.div`
  ${tw`text-center`}

  img {
    margin-left: auto;
    margin-right: auto;
  }
`

const PhotoSection: React.FC<PhotoSectionProps> = (
  props: PhotoSectionProps
) => {
  const { images } = props
  const [on, toggle] = useState<boolean>(false)
  const [activeImg, setActiveImg] = useState<IPhoto | undefined>(undefined)

  const openModal = (img: IPhoto) => {
    setActiveImg(img)
    toggle(true)
  }

  return (
    <Section bgColor={colors.grey200}>
      <Modal on={on} toggle={() => toggle(!on)}>
        <ModalContent>
          <ModalImage src={activeImg?.src} alt={activeImg?.alt} />
        </ModalContent>
      </Modal>

      <PhotoFlex>
        {images ? (
          images.map((img) => (
            <ImageContainer onClick={() => openModal(img)} src={img.src} />
          ))
        ) : (
          <>No Project Photos</>
        )}
      </PhotoFlex>
    </Section>
  )
}

PhotoSection.defaultProps = {}

export default PhotoSection
