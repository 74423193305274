import React, { ReactChild, ReactNode } from "react"
import styled from "styled-components"
import tw from "twin.macro"

import { Section } from "../../atoms"

// @ts-ignore
import colors from "../../../../config/colors"

export interface ProjectSectionProps {
  children: ReactChild | ReactNode
  img: ReactChild | ReactNode
  title: ReactChild | ReactNode
}

interface ImageWrapperProps {
  img: ReactChild | ReactNode
}

const Grid = styled.div`
  ${tw`md:grid grid-cols-2`}
`

const Text = styled.div`
  font-size: 1rem;
  line-height: 250%;
  padding: 0 5rem;
`

const TitleSection = styled.div`
  ${tw`flex content-center justify-center items-center bg-blue-600 text-white text-center border-grey-500 border-solid border-8`}
  min-height: 300px;
`

const Title = styled.h1`
  ${tw`text-white uppercase font-normal text-6xl`}
  line-height: 150%;
`

const ImageWrapper = styled.div<ImageWrapperProps>`
  ${tw`bg-cover bg-center md:block`}
  width: 100%;
  height: 100%;
  background-color: #efefef;

  ${({ img }) =>
    img &&
    `
    background-image: url(${img});
  `}
`

const ProjectSection: React.FC<ProjectSectionProps> = (
  props: ProjectSectionProps
) => {
  const { children, img, title } = props
  return (
    <>
      <Grid>
        <div>
          <TitleSection>
            <Title>{title}</Title>
          </TitleSection>
          <Section bgColor={colors.grey600} textColor={colors.white}>
            <Text dangerouslySetInnerHTML={{ __html: String(children) }} />
          </Section>
        </div>
        <div>
          <ImageWrapper img={img} />
        </div>
      </Grid>
    </>
  )
}

ProjectSection.defaultProps = {}

export default ProjectSection
