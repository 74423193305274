import React from "react"
import styled, { css } from "styled-components"
import tw from "twin.macro"

// @ts-ignore
import colors from "../../../../config/colors"

export interface BreakLineProps {
  color?: string
}

type LineProps = BreakLineProps

const bubbleCss = css<{ color?: string }>`
  height: 6px;
  width: 6px;
  margin-top: -2px;
  background: ${({ color }) => color};
  ${tw`rounded-full absolute`}
`

const Line = styled.div<LineProps>`
  ${tw`m-auto mb-8 relative`}

  width: 50px;
  height: 1px;
  background-color: ${({ color }) => color ?? colors.blue600};

  &::before,
  &::after {
    content: "";
  }
  &::before {
    left: 13px;
  }
  &::after {
    right: 13px;
  }
  div,
  &::before,
  &::after {
    ${bubbleCss}
  }

  div {
    left: 50%;
    margin-left: -3px;
  }
`

const BreakLine: React.FC<BreakLineProps> = (props: BreakLineProps) => (
  <Line {...props}>
    <div />
  </Line>
)

BreakLine.defaultProps = {
  color: colors.blue600,
}

export default BreakLine
