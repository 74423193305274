import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconProp } from "@fortawesome/fontawesome-svg-core"

export interface IconProps {
  icon: IconProp
}

const IconContainer = styled.div``

const Icon: React.FC<IconProps> = (props: IconProps) => {
  const { icon } = props

  return (
    <IconContainer>
      <FontAwesomeIcon icon={icon} />
    </IconContainer>
  )
}

Icon.defaultProps = {}

export default Icon
