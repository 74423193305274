import React, { ReactChild, ReactNode } from "react"
import { Helmet } from "react-helmet"

import GlobalStyle from "../../../../config/GlobalStyle"

export interface PageStructureProps {
  children?: ReactChild | ReactNode
  title?: string
  description?: string
}

const PageStructure: React.FC<PageStructureProps> = (
  props: PageStructureProps
) => {
  const { children, title, description } = props

  return (
    <>
      <Helmet>
        <title>
          {title ?? "Welcome To Barnett Construction"} | Barnett Construction
          Carpentry
        </title>
        <meta name="description" content={description} />
        <meta
          name="google-site-verification"
          content="V-csjb5E2PRkCVHRpo_GBlGcLuSVgvs1fsuRhvYYNZo"
        />
      </Helmet>
      <GlobalStyle />
      {children}
    </>
  )
}

export default PageStructure
