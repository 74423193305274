import React from "react"
import styled from "styled-components"
import tw from "twin.macro"

// @ts-ignore
import media from "../../../../config/media"

import { Button } from "../../atoms"

export interface IProjectBox {
  img: string
  title: string
  size?: number
  link: string
  buttonText?: string
}

export interface ProjectBoxProps {
  iconStack: IProjectBox[]
}

interface BoxImageProps {
  img: string
}

const BoxImage = styled.div<BoxImageProps>`
  ${tw`h-full w-full bg-center bg-cover absolute top-0 left-0 z-0 transition-all`}
  transition-duration: 0.5s;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  ${({ img }) =>
    img &&
    `
    background-image: linear-gradient(rgba(9, 45, 116, 0.3), rgba(9, 45, 116, 0.3)), 
    url(${img});
  `}
`

interface BoxWrapperProps {
  size: number
}

const BoxWrapper = styled.div<BoxWrapperProps>`
  ${tw`relative overflow-hidden w-full`}
  background-color: #efefef;
  height: ${({ size }) => (size ? `${size - 200}px` : "200px")};

  &:hover {
    ${BoxImage} {
      width: 110%;
      height: 110%;
    }
  }

  @media screen and (min-width: ${media.md}) {
    height: ${({ size }) => (size ? `${size}px` : "400px")};
  }
`

const Content = styled.div`
  ${tw`p-8 flex content-end items-end h-full box-border w-full absolute z-10`}

  > div {
    ${tw`w-full`}
  }
`

const ProjectTitle = styled.h3`
  ${tw`text-white font-bold uppercase text-3xl mb-6`}
  text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.5);
`

const ButtonContainer = styled.div`
  ${tw`text-center`}
`

const ProjectBox: React.FC<IProjectBox> = (props: IProjectBox) => {
  const { img, title, size, link, buttonText } = props
  return (
    <BoxWrapper size={size ?? 400}>
      <BoxImage img={img} />
      <Content>
        <div>
          <ProjectTitle>{title}</ProjectTitle>
          <ButtonContainer>
            <Button to={link}>{buttonText} &rarr;</Button>
          </ButtonContainer>
        </div>
      </Content>
    </BoxWrapper>
  )
}

ProjectBox.defaultProps = {
  buttonText: "More Details",
}

export default ProjectBox
