import React, { ReactChild, ReactNode } from "react"
import styled from "styled-components"
import tw from "twin.macro"

import { Section, BlueHeading, Wrapper } from "../../atoms"
import TradeIconStack, {
  ITradeIconStack,
} from "../TradeIconStack/TradeIconStack"

// @ts-ignore
import colors from "../../../../config/colors"

// @ts-ignore
import media from "../../../../config/media"

export interface WhoWeAreSectionProps {
  children: ReactChild | ReactNode
  categories?: ITradeIconStack[]
  img?: string
}

const WhoGrid = styled.div`
  ${tw`grid grid-cols-1 md:grid-cols-2 px-4`}
  grid-column-gap: 2rem;
  align-items: center;
`

const ContactContent = styled.div`
  ${tw`mb-8`}
`

const ImageColumn = styled.div`
  ${tw`text-center md:block`}

  img {
    height: 300px;
    width: 300px;
    max-width: 100%;
    border-radius: 100%;
    ${tw`m-auto object-cover md:mb-0 mb-8`}
  }

  @media screen and (min-width: ${media.md}) {
    img {
      height: 500px;
      width: 500px;
    }
  }
`

const IconGrid = styled.div`
  ${tw`grid grid-cols-1 md:grid-cols-2 mt-16 mb-8`}
  grid-gap: 3rem;
`

const WhoWeAreSection: React.FC<WhoWeAreSectionProps> = (
  props: WhoWeAreSectionProps
) => {
  const { children, img, categories } = props
  return (
    <Section bgColor={colors.grey200}>
      <WhoGrid>
        <ImageColumn>{img && <img src={img} alt="Who We Are" />}</ImageColumn>
        <div id="whoweare">
          <ContactContent>
            <BlueHeading>WHO WE ARE</BlueHeading>
            <div dangerouslySetInnerHTML={{ __html: String(children) }} />
          </ContactContent>
        </div>
      </WhoGrid>
      {categories && (
        <Wrapper maxWidth={900}>
          <IconGrid>
            {categories.map((icon, ix) => (
              <TradeIconStack
                icon={icon.icon}
                title={icon.title}
                content={(icon.content as string) ?? ""}
                anchorID={icon.id}
                index={ix}
              />
            ))}
          </IconGrid>
        </Wrapper>
      )}
    </Section>
  )
}

WhoWeAreSection.defaultProps = {
  categories: [],
}

export default WhoWeAreSection
